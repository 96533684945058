import { template as template_dfe7083fba1f4c188d1e83ac233e597e } from "@ember/template-compiler";
const WelcomeHeader = template_dfe7083fba1f4c188d1e83ac233e597e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
