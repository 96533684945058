import { template as template_69a65ba036c0441ab23150a44c66b9c4 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_69a65ba036c0441ab23150a44c66b9c4(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
